import React from "react";

const pagesRoutes = [
  {
    path: "/dashboard",
    component: React.lazy(() => import("./dashboard/dashboard")),
  },

  {
    path: "/create-order",
    component: React.lazy(() => import("./order/posOrder/posOrder")),
  },
  {
    path: "/update-order/:serialId",
    component: React.lazy(() =>
      import("./order/posOrderUpdate/posOrderUpdate")
    ),
  },
  {
    path: "/update-affiliate-order/:serialId/:type",
    component: React.lazy(() =>
      import("./order/posOrderUpdate/posOrderUpdate")
    ),
  },
  {
    path: "/order-list",
    component: React.lazy(() => import("./order/orderList/orderlist")),
  },
  {
    path: "/order-view/:serialId",
    component: React.lazy(() => import("./order/orderView/orderView")),
  },

  {
    path: "/multiple-order-view",
    component: React.lazy(() => import("./order/orderView/multipleOrderView")),
  },

  {
    path: "/order-return-refund",
    component: React.lazy(() => import("./order/returnRefund/returnRefund")),
  },

  {
    path: "/create-purchase",
    component: React.lazy(() =>
      import("./purchase/purchaseCreate/createPurchase")
    ),
  },

  {
    path: "/create-purchase-balance",
    component: React.lazy(() => import("./purchase/stockManage/stockProcess")),
  },
  {
    path: "/purchase-list",
    component: React.lazy(() => import("./purchase/purchaseList/purchaseList")),
  },
  {
    path: "/stockProcess-list",
    component: React.lazy(() =>
      import("./purchase/stockManage/stockProcessList")
    ),
  },
  {
    path: "/purchase-view/:serialId",
    component: React.lazy(() => import("./purchase/purhaseView/purchaseView")),
  },
  {
    path: "/customer",
    component: React.lazy(() => import("./customer/customerList")),
  },
  {
    path: "/customer-create",
    component: React.lazy(() => import("./customer/createCustomer.jsx")),
  },
  {
    path: "/customer-update/:id",
    component: React.lazy(() => import("./customer/updateCustomer.jsx")),
  },
  {
    path: "/sms-list",
    component: React.lazy(() => import("./sms/smsList")),
  },
  {
    path: "/direct-sms",
    component: React.lazy(() => import("./sms/bulkMsgSend")),
  },
  {
    path: "/create-supplier",
    component: React.lazy(() => import("./supplier/createSupplier")),
  },
  {
    path: "/update-supplier/:supplierId",
    component: React.lazy(() => import("./supplier/updateSupplier")),
  },
  {
    path: "/supplier-list",
    component: React.lazy(() => import("./supplier/supplierList")),
  },
  {
    path: "/expense-head",
    component: React.lazy(() => import("./expenseHead/expenseHead")),
  },
  {
    path: "/create-expense",
    component: React.lazy(() => import("./expense/createExpense")),
  },
  {
    path: "/update-expense/:expenseId",
    component: React.lazy(() => import("./expense/updateExpense")),
  },
  {
    path: "/expense-list",
    component: React.lazy(() => import("./expense/expenseList")),
  },
  {
    path: "/create-product",
    component: React.lazy(() =>
      import("./product/createProduct/createProducts")
    ),
  },
  {
    path: "/update-product/:productSlug",
    component: React.lazy(() =>
      import("./product/updateProduct/updateProducts")
    ),
  },
  {
    path: "/product-list",
    component: React.lazy(() => import("./product/productList/productList")),
  },
  // {
  //   path: "/alert-products",
  //   component: React.lazy(() => import("./product/stockAlert/stockAlert")),
  // },
  {
    path: "/create-category",
    component: React.lazy(() => import("./category/createCategory")),
  },
  {
    path: "/update-category/:categoryId",
    component: React.lazy(() => import("./category/updateCategory")),
  },
  {
    path: "/category-list",
    component: React.lazy(() => import("./category/CategoryList")),
  },
  {
    path: "/create-attribute",
    component: React.lazy(() => import("./attributes/createAttribute")),
  },
  {
    path: "/update-attribute/:attributeId",
    component: React.lazy(() => import("./attributes/updateAttribute")),
  },
  {
    path: "/attribute-list",
    component: React.lazy(() => import("./attributes/AttributeList")),
  },
  {
    path: "/create-attribute-value",
    component: React.lazy(() => import("./attributes/createAttributeValue")),
  },
  {
    path: "/update-attribute-value/:attributeValueId",
    component: React.lazy(() => import("./attributes/updateAttributeValue")),
  },
  {
    path: "/attribute-value-list",
    component: React.lazy(() => import("./attributes/AttributeValueList ")),
  },
  {
    path: "/create-brand",
    component: React.lazy(() => import("./brand/createBrand")),
  },
  {
    path: "/update-brand/:brandId",
    component: React.lazy(() => import("./brand/updateBrand")),
  },
  {
    path: "/brand-list",
    component: React.lazy(() => import("./brand/brandList")),
  },
 
  {
    path: "/create-promo",
    component: React.lazy(() => import("./promo/createPromo")),
  },
  {
    path: "/promo-list",
    component: React.lazy(() => import("./promo/promoList")),
  },
  {
    path: "/create-campaign",
    component: React.lazy(() => import("./campaign/createCampaign")),
  },
  {
    path: "/update-campaign/:campaignId",
    component: React.lazy(() => import("./campaign/updateCampaign")),
  },
  {
    path: "/view-campaign/:campaignId",
    component: React.lazy(() => import("./campaign/Details/viewCampaign")),
  },
  {
    path: "/campaign-list",
    component: React.lazy(() => import("./campaign/campaignList")),
  },

  {
    path: "/flash-deal",
    component: React.lazy(() => import("./flashdeal/flashdeal")),
  },

  {
    path: "/home-view",
    component: React.lazy(() => import("./homeView/homeView")),
  },
  {
    path: "/page-update",
    component: React.lazy(() => import("./singlePage/pageUpdate")),
  },
  {
    path: "/page-view",
    component: React.lazy(() => import("./singlePage/pagesView")),
  },
  {
    path: "/delivery-charge",
    component: React.lazy(() => import("./deliveryCharge/deliveryCharge")),
  },
  {
    path: "/pop-up-img",
    component: React.lazy(() => import("./media/popupImg")),
  },
  {
    path: "/slider-img",
    component: React.lazy(() => import("./media/sliders")),
  },
  {
    path: "/brand-img",
    component: React.lazy(() => import("./media/brandImg")),
  },
  {
    path: "/fb-pixel",
    component: React.lazy(() => import("./media/fbPixel")),
  },
  {
    path: "/google-scripts",
    component: React.lazy(() => import("./media/google")),
  },
  {
    path: "/other-scripts",
    component: React.lazy(() => import("./media/thirdPartyScripts")),
  },
  {
    path: "/banner-img",
    component: React.lazy(() => import("./media/bannerImg")),
  },
  {
    path: "/banner-text",
    component: React.lazy(() => import("./media/bannerText")),
  },
  {
    path: "/create-employee",
    component: React.lazy(() => import("./employee/createEmployee")),
  },
  {
    path: "/employee-list",
    component: React.lazy(() => import("./employee/employeeList.jsx")),
  },
  {
    path: "/employee-role/:employeeId",
    component: React.lazy(() => import("./employee/rolePermission")),
  },
  {
    path: "/update-employee/:empId",
    component: React.lazy(() => import("./employee/updateEmployee.jsx")),
  },
  {
    path: "/profile",
    component: React.lazy(() => import("./general/profile")),
  },
  {
    path: "/password",
    component: React.lazy(() => import("./general/password")),
  },
  {
    path: "/product-report",
    component: React.lazy(() => import("./report/productReport")),
  },
  {
    path: "/product-transfer",
    component: React.lazy(() => import("./product/transferProduct.jsx")),
  },
  {
    path: "/product-transfer-list",
    component: React.lazy(() => import("./product/transferListAdmin.jsx")),
  },
  {
    path: "/product-transfer-view/:Id",
    component: React.lazy(() => import("./product/transferView.jsx")),
  },
  {
    path: "/product-transfer-sender",
    component: React.lazy(() => import("./product/senderlist.jsx")),
  },
  {
    path: "/product-receiver-list",
    component: React.lazy(() => import("./product/RecieverList.jsx")),
  },
  {
    path: "/account-report",
    component: React.lazy(() => import("./report/accountReport")),
  },

 


  {
    path: "/search-order/admin",
    component: React.lazy(() => import("./order/orderSearch/orderSearch")),
  },


  {
    path: "/searchOrder-invoice",
    component: React.lazy(() =>
      import("./order/orderSearch/searchOrderForInvoice")
    ),
  },

  {
    path: "/manual-payment",
    component: React.lazy(() => import("./general/manualPayment")),
  },
  {
    path: "/manual-payment-list",
    component: React.lazy(() => import("./general/paymentList")),
  },
  {
    path: "/update-manual-pay/:payId",
    component: React.lazy(() => import("./general/updateManualPayment")),
  },

  {
    path: "/create-store",
    component: React.lazy(() => import("./store/createStore.jsx")),
  },
  {
    path: "/store-list",
    component: React.lazy(() => import("./store/storeList.jsx")),
  },
  {
    path: "/update-store/:storeId",
    component: React.lazy(() => import("./store/updateStore.jsx")),
  },

  {
    path: "/create-unit",
    component: React.lazy(() => import("./unit/createUnit.jsx")),
  },
  {
    path: "/unit-list",
    component: React.lazy(() => import("./unit/unitList.jsx")),
  },
  {
    path: "/update-unit/:unitId",
    component: React.lazy(() => import("./unit/updateUnit.jsx")),
  },
  {
    path: "/create-bank",
    component: React.lazy(() => import("./account/bank/createBankAccount.jsx")),
  },
  {
    path: "/bank-list",
    component: React.lazy(() => import("./account/bank/bankList.jsx")),
  },
  {
    path: "/update-bank/:bankId",
    component: React.lazy(() => import("./account/bank/updateBank.jsx")),
  },
  {
    path: "/create-mobile-banking",
    component: React.lazy(() => import("./account/mobileBank/createMobileBank.jsx")),
  },
  {
    path: "/mobile-bank-list",
    component: React.lazy(() => import("./account/mobileBank/mobileBanklist.jsx")),
  },
  {
    path: "/update-mobile-bank/:bankId",
    component: React.lazy(() => import("./account/mobileBank/updateMobileBank.jsx")),
  },
  {
    path: "/create-quotation",
    component: React.lazy(() => import("./quatation/createQuatation.jsx")),
  },
  {
    path: "/quotation-list",
    component: React.lazy(() => import("./quatation/quoteList.jsx")),
  },
  {
    path: "/quotation-view/:id",
    component: React.lazy(() => import("./quatation/quoteView.jsx")),
  },
  {
    path: "/quotation-update/:id",
    component: React.lazy(() => import("./quatation/updateQuote.jsx")),
  },
  {
    path: "/quotation-to-order/:id",
    component: React.lazy(() => import("./quatation/quoteToOrder.jsx")),
  },
  {
    path: "/supplier-payment-list",
    component: React.lazy(() => import("./payment/supplierPaymentList.jsx")),
  },
  {
    path: "/customer-payment-list",
    component: React.lazy(() => import("./payment/customerPaymentList.jsx")),
  },
  {
    path: "/supplier-wise-payment-list/:id",
    component: React.lazy(() => import("./payment/supplierWisePaymentList.jsx")),
  },
  {
    path: "/supplier-pending-payment-list",
    component: React.lazy(() => import("./payment/supplierPendingPaymentList.jsx")),
  },
  {
    path: "/customer-wise-payment-list/:id",
    component: React.lazy(() => import("./payment/customerWisePaymentList.jsx")),
  },
  {
    path: "/customer-pending-payment-list",
    component: React.lazy(() => import("./payment/customerPendingPaymentList.jsx")),
  },
 
 
];

export default pagesRoutes;
