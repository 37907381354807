import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? `http://localhost:2711/api/v1`
  //     : `https://api.jbs.b2gsoft.com/api/v1`,

  baseURL: `https://api.jbs.b2gsoft.com/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
